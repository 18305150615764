import React from "react";

function Header() {
  return (
    <div>
      <div class="grid grid-cols-1 md:grid-cols-2">
        <div class="p-4">
          <div className="flex flex-row items-center">
            <div
              className="w-4 h-4"
              style={{ backgroundColor: "#4D3B47" }}
            ></div>
            <p className="border-purple-700 text-xl leading-6 font-mono font-medium ml-4 text-center md:text-left">
              Product Mindset
            </p>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 overflow-x-hidden">
        <div class="col-span-1 md:col-span-2 p-4 my-1">
          <div class="p-2 md:ml-6">
            <p class="text-custom-color text-lg md:text-xl font-space-mono font-semibold text-center md:text-left">
              Welcome to Product Mindset Ideas
            </p>
          </div>
          <div class="mt-4 md:mt-0 ml-2 md:ml-6">
            <p class="text-custom-color text-lg md:text-xl md:ml-2 md:mt-4 font-space-mono text-center md:text-left">
              Unleash your creativity and unlock limitless possibilities with
              our Product Mindset Ideas platform.
            </p>
          </div>
        </div>
      </div>

      <hr class="mt-4 mb-2 border-t-2 border-solid border-6D6868 ml-2 mr-2"></hr>
    </div>
  );
}

export default Header;
