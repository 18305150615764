import { createSlice } from "@reduxjs/toolkit";

export const ideasSlice = createSlice({
  name: "counter",
  initialState: {
    value: 0,
    ideas_data: [],
    email: '',
  },
  reducers: {
    increment: (state) => {
      if (state.value < state.ideas_data.length) {
        state.value += 1;
      }
    },
    decrement: (state) => {
      if (state.value > 0) {
        state.value -= 1;
      }
    },
    updateIdeasData: (state, action) => {
      state.ideas_data = action.payload;
    },
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { increment, decrement, updateIdeasData,updateEmail } = ideasSlice.actions;

export default ideasSlice.reducer;
