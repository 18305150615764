import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { updateEmail } from "../features/ideas/ideasSlice";
import axios from "axios";

function Footer() {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.counter.email);
  const handleEmailChange = (event) => {
    dispatch(updateEmail(event.target.value));
  };
  const onSubmitButton = () => {
    axios
      .post("https://quotes-pi-sand.vercel.app/api/v1/ideas/addemail", {
        email: email,
      })
      .then(
        (response) => {
          dispatch(updateEmail(""));
        },
        (error) => {
          dispatch(updateEmail(""));
        }
      );
  };
  return (
    <div>
      <hr class="mt-4 mb-2 border-t-2 border-solid border-6D6868 ml-2 mr-2"></hr>
      <div class="grid grid-cols-1 md:grid-cols-2">
        <div class="col-span-1 md:col-span-2 p-4 my-3">
          <div class="flex justify-center">
            <div class="flex items-center bg-gray-200 rounded-lg px-4 py-2 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
              <input
                type="email"
                placeholder="Enter your email"
                class="bg-transparent outline-none flex-grow mr-2"
                value={email}
                onChange={handleEmailChange}
              />
              <button
                class="bg-custom-gray text-white px-3 md:px-6 py-2 md:px-10 rounded"
                onClick={onSubmitButton}
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center items-center">
        <div class="max-w-screen-lg mx-auto">
          <div class="grid grid-cols-1">
            <div class="col-span-1 flex justify-center items-center">
              <p class="text-custom-color text-center text-xl font-space-mono my-2  ml-3 mr-3">
                Supercharge your product innovation with Product Mindset Ideas!
                Sign up now for early access and unlock a world of
                groundbreaking possibilities.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center items-center">
        <div class="max-w-screen-lg mx-auto">
          <div class="grid grid-cols-1">
            <div class="col-span-1 flex justify-center items-center">
              <p class="text-custom-color text-center text-xl font-space-mono my-8  ml-3 mr-3">
                ❤️ Built with love by Product Mindset Team
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
