import React from "react";

function Content() {
  return (
    <div>
      <div class="grid grid-cols-1 md:grid-cols-2">
        <div class="col-span-2 md:col-span-1  p-4 my-3">
          <div class="p-2 ml-4 md:ml-6">
            <p class="text-custom-color text-xl font-space-mono font-semibold">
              {/* Supercharge Your Product Innovation with GPT-4 */}
            </p>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2">
        <div class="col-span-1 md:col-span-2 pl-6 my-1">
          <div class="w-full md:w-auto ml-2 md:ml-6">
            <p class="text-custom-color text-xl font-space-mono">
            We leverage the cutting-edge power of GPT-4, the latest generation of AI technology, to fuel your product innovation journey. Unleash your creativity and unlock limitless possibilities with our Product Mindset Ideas platform.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;
