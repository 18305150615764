import "./App.css";

import Header from "./components/Header";
import Idea from "./components/Idea";
import Content from "./components/Content";
import Footer from "./components/Footer";
// import { useSelector, useDispatch } from "react-redux";
// import { decrement, increment } from "./features/ideas/ideasSlice";

function App() {
  // const count = useSelector((state) => state.counter.value);
  // const dispatch = useDispatch();

  return (
    <div class="overflow-auto">
      <Header></Header>
      <Idea></Idea>
      <Content></Content>
      <Footer></Footer>
    </div>
  );
}

export default App;
