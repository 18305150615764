import React, { useEffect } from "react";
import axios from "axios";

import { useSelector, useDispatch } from "react-redux";
import {
  updateIdeasData,
  increment,
  decrement,
} from "../features/ideas/ideasSlice";

function Idea() {
  const ideas_list = useSelector((state) => state.counter.ideas_data);
  const value = useSelector((state) => state.counter.value);
  const dispatch = useDispatch();

  const fetchData = () => {
    axios
      .get("https://quotes-ap33ql268-productmindset36.vercel.app/api/v1/ideas")
      .then((response) => {
        dispatch(updateIdeasData(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
  });

  return (
    <div>
      <div class="mt-12 flex justify-center">
        <div class="w-full md:w-screen max-w-3xl bg-gray-200 p-4 md:p-8 mx-4 rounded relative">
          <p class="font-space-mono font-semibold leading-loose text-lg tracking-wide leading-relaxed text-xl">
            {ideas_list.length
              ? ideas_list[value].idea
              : "A platform to help farmers in India to manage their crops and sell directly to consumers over the internet. In the short time since launch, it has sold $1.6 million worth of produce."}
          </p>
          {/* <div class="absolute bottom-2 right-2 md:bottom-0 md:right-8 text-gray-500 text-xs p-1 rounded ">
            {
              ideas_list[value].categ ? ideas_list[value].categ.split("/").slice(-1)[0]:"- platform-sta"
            }
          </div> */}
        </div>
      </div>

      <div class="mt-4 flex justify-center">
        <div class="flex items-center space-x-4">
          <button
            class="bg-custom-gray text-white py-2 px-4 rounded"
            onClick={() => dispatch(decrement())}
          >
            Previous 
          </button>

          <button
            class="bg-second-color text-white py-2 px-4 rounded"
            onClick={() => dispatch(increment())}
          >
            Next Idea
          </button>
        </div>
      </div>
    </div>
  );
}

export default Idea;
